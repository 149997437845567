import React, { useState, useEffect } from 'react';
import topImage from './img/download.jpeg';
import logo from "./img/Logo (1).png";
import { useNavigate } from 'react-router-dom';
import { Config } from '../.env.js';
import axios from 'axios';

const AllCamp = ({ campaign = "Hello", alignLeft, }) => {
    // Styles for the top image card
    const { campaignName, managers } = campaign;
    const [product, setProduct] = useState(null);
    // const { image } = product
    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const response = await axios.get(`${Config.API_URL}/api/product`, {
                    params: { campaignName } // Send campaignName as parameter if needed
                });
                setProduct(response.data); // Update state with fetched data
            } catch (error) {
                console.error("Error fetching product:", error);
            }
        };

        fetchProduct();
    }, [campaignName]);
    const topImageStyle = {
        backgroundImage: `url(${topImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        height: '170px', // Adjust height as needed
        width: '450px', // Full width to match the button card below
        borderRadius: '6px',
        marginBottom: '-10px',
        marginLeft: '-15px',
        marginTop: '-13px',

    };
    const logoStyle = {
        position: 'absolute',
        bottom: '100px',
        left: '35px',
        width: '40px',
        height: '36px',
        top: "300px"
    };

    const navigate = useNavigate();

    const title = {
        title: "Subtitle"
    };
    const brandName = {
        brandName: "BrandName"
    }

    const followersCriteria = {
        followersCriteria: "followersCriteria"
    }
    const deliverables = {
        deliverables: "deliverables"
    }
    const GiveProductsWorth = {
        GiveProductWorth: "Give Products Worth"
    }
    const dealtype = {
        dealtype: "deal type"
    }
    const handleApply = () => {
        navigate(`/campaignDetails`, { state: { campaignName } });
    };

    const handleApplyDeliverables = () => {
        navigate(`/order-form`, { state: { campaignName } });

    };

    const handleConfirmation = () => {
        navigate(`/refund-form`, { state: { campaignName } });
    };

    const handleOrders = () => {
        navigate(`/order-table`, { state: { campaignName } });
    };

    return (
        <div className='is-max-desktop'>
            <div >
                {/* Button Card */}
                <div className="card-content">
                    <div className="box mt-6 ml-5" style={{ width: 476, height: 550, }}>
                        <div style={topImageStyle}>
                            <img src={logo} alt="Logo" style={logoStyle} />
                        </div>
                        <br />
                        <div>
                            {/* Brand Name and Followers Criteria Section */}
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <span className="icon">
                                        <i className="fas fa-tag"></i>
                                    </span>
                                    <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Brand Name</p>
                                    <p className='has-text-weight-bold'>{brandName.brandName}</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                    <span className="icon" style={{ marginRight: "125px" }}>
                                        <i className="fas fa-user-friends"></i> {/* Icon for followers */}
                                    </span>
                                    <p className="has-text-grey-light mr-5" >Followers Criteria</p>
                                    <p className='has-text-weight-bold mr-5'>{followersCriteria.followersCriteria}</p>
                                </div>
                            </div>

                            {/* deliverables and Give Products Worth Section */}
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <span className="icon">
                                        <i className="fas fa-tag"></i>
                                    </span>
                                    <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Deliverables</p>
                                    <p className='has-text-weight-bold'>{deliverables.deliverables}</p>
                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
                                    <span className="icon" style={{ marginRight: "130px" }}>
                                        <i className="fas fa-user-friends"></i> {/* Icon for followers */}
                                    </span>
                                    <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Give Products Worth</p>
                                    <p className='has-text-weight-bold mr-1'>{GiveProductsWorth.GiveProductWorth}</p>
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <span className="icon">
                                        <i className="fas fa-tag"></i>
                                    </span>
                                    <p className="has-text-grey-light" style={{ marginRight: "10px" }}>Deal Type</p>
                                    <p className='has-text-weight-bold'>{dealtype.dealtype}</p>
                                </div>
                            </div>


                            <div style={{ flexDirection: 'row', gap: '10px', }}>
                                <button onClick={handleApply} className="button is-small-custom  mt-3 ml-1 has-text-info " style={{ width: '210px', height: '30px' }}>View Campaign</button>
                                <button onClick={handleApplyDeliverables} className="button is-small-custom  mt-3 ml-1 has-text-info " style={{ width: '210px', height: '30px' }}>View Order Form</button>

                                <button onClick={handleConfirmation} className="button is-small-custom  mt-2 ml-1 has-text-info " style={{ width: '210px', height: '30px' }}>View Refund Form</button>
                                <button onClick={handleOrders} className="button is-small-custom  mt-2 ml-1 has-text-info" style={{ width: '210px', height: '30px' }}>View Orders</button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default AllCamp;
