import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import topImage from "./img/download.jpeg";
import logo from "./img/Logo (1).png";
import OrderTable from './orderTable.js'
import Refund from '../pages/refundForm.js';
import OrderForm from '../pages/orderForm.js';
import { Config } from '../.env.js';



const Card = ({ campaign = "Hello", alignLeft }) => {
    const { campaignName, managers, image } = campaign;


    const navigate = useNavigate();
    const topImageContainerStyle = {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        height: '150px',
        width: '100%',
        backgroundImage: `url(${topImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '4px',
        marginBottom: '0px',
        marginRight: "30px"
    };

    const logoStyle = {
        position: 'absolute',
        marginLeft: '10px',
        width: '40px',
        height: '30px',
        top: '110px',
        zIndex: 2,
    };
    // if (!campaign) {
    //     console.error('Campaign is undefined'); // Debugging log
    //     return null;
    // }

    const handleApply = () => {
        navigate(`/order-table`, { state: { campaignName } });
    };

    const handleApplyDeliverables = () => {
        navigate(`/order-form`, { state: { campaignName } });

    };

    const handleConfirmation = () => {
        navigate(`/refund-form`, { state: { campaignName } });
    };

    const handleOrders = () => {
        navigate(`/order-table`, { state: { campaignName } });
    };
    const [tooltipVisible, setTooltipVisible] = useState(false);
    const [tooltipContent, setTooltipContent] = useState("");

    const Participate = true;
    const PaymentsFormFilled = true;
    const PaymentsMadeCampaignCompleted = true;

    const getProgressWidth = () => {
        if (Participate && !PaymentsFormFilled) return "10%"; // Up to "Participated"
        if (Participate && PaymentsFormFilled && !PaymentsMadeCampaignCompleted) return "33%"; // Up to "Payments Form Filled"
        if (Participate && PaymentsFormFilled && PaymentsMadeCampaignCompleted) return "100%"; // Full progress
        return "0%"; // No progress
    };

    const getProgressPercentage = () => {
        if (Participate && PaymentsFormFilled && PaymentsMadeCampaignCompleted) return "100% - Payment Made & Campaign Successfully Completed";
        if (Participate && PaymentsFormFilled) return "66% - Payments Form Filled";
        if (Participate) return "33% - Participated";
        return "0%";
    };

    const handleMouseEnter = () => {
        setTooltipVisible(true);
        setTooltipContent(getProgressPercentage());
    };

    const handleMouseLeave = () => {
        setTooltipVisible(false);
    };
    const getIconColor = (step) => {
        const progressWidth = getProgressWidth();
        if (step === "Participated" && progressWidth !== "0%") return "blue";
        if (step === "Payments Form Filled" && (progressWidth === "33%" || progressWidth === "100%")) return "blue";
        if (step === "Payment Made & Campaign Successfully Completed" && progressWidth === "100%") return "blue";
        return "black";
    };


    const brandName = {
        brandName: "BrandName"
    }

    const followersCriteria = {
        followersCriteria: "followersCriteria"
    }
    const deliverables = {
        deliverables: "deliverables"
    }
    const GiveProductsWorth = {
        GiveProductWorth: "Give Products Worth"
    }
    const dealtype = {
        dealtype: "deal type"
    }
    return (
        <div className='is-max-desktop'>
            <div className="card-content">
                <div className="box" style={{ width: '100%', maxWidth: '450px', height: 'auto', overflowX: 'hidden', margin: '0 auto' }}>
                    <div style={topImageContainerStyle}>
                        <img src={logo} alt="Logo" style={logoStyle} />
                    </div>
                    <br />
                    <div>
                        {/* Brand Name and Followers Criteria Section */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <span className="icon">
                                    <i className="fas fa-tag"></i>
                                </span>
                                <p className="has-text-grey-light">Brand Name</p>
                                <p className='has-text-weight-bold'>{brandName.brandName}</p>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginRight: '35px' }}>
                                <span className="icon">
                                    <i className="fas fa-user-friends"></i>
                                </span>
                                <p className="has-text-grey-light">Followers Criteria</p>
                                <p className='has-text-weight-bold'>{followersCriteria.followersCriteria}</p>
                            </div>
                        </div>

                        {/* Deliverables and Give Products Worth Section */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <span className="icon">
                                    <i className="fas fa-tag"></i>
                                </span>
                                <p className="has-text-grey-light">Deliverables</p>
                                <p className='has-text-weight-bold'>{deliverables.deliverables}</p>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', marginLeft: '30px' }}>
                                <span className="icon">
                                    <i className="fas fa-gift"></i>
                                </span>
                                <p className="has-text-grey-light">Give Products Worth</p>
                                <p className='has-text-weight-bold'>{GiveProductsWorth.GiveProductWorth}</p>
                            </div>
                        </div>

                        {/* Deal Type Section */}
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: "20px" }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <span className="icon">
                                    <i className="fas fa-tag"></i>
                                </span>
                                <p className="has-text-grey-light">Deal Type</p>
                                <p className='has-text-weight-bold'>{dealtype.dealtype}</p>
                            </div>
                        </div>

                        {/* Progress Line Section */}
                        <div style={{ marginTop: "20px", position: 'relative' }}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '5px' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <span className="icon">
                                        <i className="fas fa-check-circle" style={{ color: getIconColor("Participated") }}></i>
                                    </span>
                                    <br />
                                    Participated
                                </div>
                                <div style={{ textAlign: 'center', marginLeft: "10px" }}>
                                    <span className="icon">
                                        <i className="fas fa-pencil-alt" style={{ color: getIconColor("Payments Form Filled") }}></i>
                                    </span>
                                    <br />
                                    Payments Form Filled
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <span className="icon">
                                        <i className="fas fa-check-double" style={{ color: getIconColor("Payment Made & Campaign Successfully Completed") }}></i>
                                    </span>
                                    <br />
                                    Payment Made & Campaign Successfully Completed
                                </div>
                            </div>
                            <div style={{ width: '100%', height: '5px', backgroundColor: '#e0e0e0', borderRadius: '5px' }}>
                                <div
                                    onMouseEnter={handleMouseEnter}
                                    onMouseLeave={handleMouseLeave}
                                    style={{
                                        width: getProgressWidth(),
                                        height: '100%',
                                        backgroundColor: 'black',
                                        borderRadius: '5px',
                                        transition: 'width 0.5s ease-in-out'
                                    }}
                                />
                            </div>
                            {tooltipVisible && (
                                <div style={{
                                    position: 'absolute',
                                    bottom: '30px',
                                    left: `${parseInt(getProgressWidth()) - 50}px`,
                                    backgroundColor: 'white',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    padding: '5px',
                                    zIndex: 10,
                                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
                                }}>
                                    {tooltipContent}
                                </div>
                            )}
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', flexWrap: 'wrap' }}>
                            <button onClick={handleOrders} className="button is-small mt-3 has-text-info">View Campaign Details</button>
                            <button onClick={handleApplyDeliverables} className="button is-small mt-3 has-text-info">Participate</button>
                            <button onClick={handleConfirmation} className="button is-small mt-3 has-text-info">Add Refund Details</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Card;
